export class Utils {


    public static findElementById(elementId: string): HTMLElement | null {
        let currentWindow: Window | null = window;
        while (currentWindow) {
            const element: HTMLElement | null = currentWindow.document.getElementById(elementId);
            if (element) {
                return element;
            }
            if (currentWindow === currentWindow.parent) {
                break;
            }
            currentWindow = currentWindow.parent;
        }
        return null;
    }

    public static getAppLink(): string {
        const element: HTMLElement | null = Utils.findElementById('APPLICATION_LINK');
        if (element != null) {
            return (<HTMLInputElement>element).value;
        } else {
            let appLink = window.location.origin + "/";
            let path = window.location.pathname;
            if ((path.match(/\//g) || []).length > 1) {
                appLink += "/" + path.split("/")[1] + "/";
            }
            return appLink;
        }
    }
}